import React from 'react'
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';
import Button from '../../../components/v2/Button';
import { navigate } from 'gatsby';
import { getUrlParams } from '../../../helpers/location';

export default ({ location }) => {
  const params = getUrlParams(location.search);
  const { corporate, room } = params;

  const addProfile = () => {
    navigate(`/emori/relation/profileMember?corporate=${corporate}&room=${room}`, { replace: true });
  }

  const onClose = () => {
    navigate(`/emori/relation`);
  }

  return (
    <Layout bg='bg4' title=''>
      <Box p={2} display='flex' alignItems='center' flexDirection='column'>
        <CheckCircleOutlineIcon style={{ color: green[300], fontSize: 60 }} />
        <Typography variant='h5' component='h1' color='textPrimary' style={{ margin: '30px 0px 5px', fontSize: 30 }}>
          เพิ่มโปรไฟล์สำเร็จ
        </Typography>
        <Box pt={4}>
          <Button
            fullWidth
            variant='outlined'
            size='large'
            onClick={() => addProfile()}
          >
            เพิ่มโปรไฟล์อีก
          </Button>
        </Box>
        <Box pt={2}>
          <Button
            fullWidth
            variant='contained'
            size='large'
            onClick={() => onClose()}
          >
            ปิดหน้าต่างนี้
          </Button>
        </Box>
      </Box>
    </Layout >
  );
}
